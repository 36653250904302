import React, { useEffect, useState } from 'react';
import { Box } from 'rebass/styled-components';
import { graphql } from 'gatsby';

import withI18next from '../../i18n/TemplateI18n';
import { GetContactData } from '../../storage/provider';
import {
  getCampusName,
  getCampusMeetup,
  getCampusFacebook,
} from '../../lib/campus';
import { getLinkByPageName } from '../../lib/links';
import Deindex from '../../components/Deindex';
import Footer from '../../components/Footer';
import ThankYouTitle from '../../components/ThankYouTitle';
import CheckboxTable from '../../components/CheckboxTable';
import Layout from '../../components/atoms/layout';
import PropsThanksForApply from '../../interface/template';

const ThanksForContact: React.SFC<any> = (props: PropsThanksForApply) => {
  const { t, pageContext } = props;
  const { pageName } = pageContext;
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    course: '',
    campus: '',
  });

  useEffect(() => {
    setUserData(GetContactData());
  }, []);

  return (
    <>
      <Deindex />
      <Box mb={[200, 300]}>
        <Layout>
          <ThankYouTitle
            subtitles={[
              t(
                'retina-landing:retinaLanding.thankyouPages.application.subtitle'
              ),
            ]}
            title={t(
              'retina-landing:retinaLanding.thankyouPages.application.title'
            )}
          />
        </Layout>
      </Box>

      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: { regex: "/(menu)|(retina-landing)|(seo)|(breadcrumbs)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(ThanksForContact);
